import store from '@/store'
const channelId = 'fI6Qn6'
const im = {
    /* eslint-disable no-inner-declarations */
    intIm() {
        const { uuid: uniqueId } = store.state.user.user
        if (!window._AIHECONG && uniqueId) {
            //接入代码在客服工作台「客户接入渠道」里创建生成
            (function (d, w, c) {
                var s = d.createElement('script');
                w[c] = w[c] || function () {
                    (w[c].z = w[c].z || []).push(arguments);
                };
                s.async = true;
                s.src = 'https://static.ahc.ink/hecong.js';
                if (d.head) d.head.appendChild(s);
            })(document, window, '_AIHECONG');
            window._AIHECONG('ini', { channelId, uniqueId, button: false });
            //以上是网页接入代码
            this.hideChat()
            //未读消息的监听与回调
            this.unreadCallback()
        }

    },
    customer() {
        const { name, phone, company } = store.state.user.user;
        let params = {};
        name && (params['名称'] = name)
        phone && (params['手机'] = phone)
        // name&&(params.name = company.name)
        window._AIHECONG && window._AIHECONG('customer', params);
    },
    //更新
    update(params = {}) {
        window._AIHECONG && window._AIHECONG('update', { channelId, ...params });
    },
    //打开窗口
    openChat() {
        window._AIHECONG && window._AIHECONG('showChat');
        store.dispatch('im/clearUnreadList')
    },
    //关闭窗口
    hideChat() {
        window._AIHECONG && window._AIHECONG('hideChat');
    },
    //未读回调
    unreadCallback() {
        window._AIHECONG && window._AIHECONG('unreadCallback', unreadList => {
            store.dispatch('im/setUnreadList', unreadList)
        });
    },
    // 触发消息
    triggerMessage(contents) {
        this.openChat()
        window._AIHECONG && contents && window._AIHECONG('triggerMessage', {
            contents
        });
    }

}
export default im