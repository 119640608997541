import { render, staticRenderFns } from "./qianshu.vue?vue&type=template&id=45054c96&scoped=true"
import script from "./qianshu.vue?vue&type=script&lang=js"
export * from "./qianshu.vue?vue&type=script&lang=js"
import style0 from "./qianshu.vue?vue&type=style&index=0&id=45054c96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45054c96",
  null
  
)

export default component.exports