
import { mapState,mapGetters } from 'vuex'
export default {
    computed: {
        ...mapState({
            user:state=>state.user.user,
            balance:state=>state.user.balance,
        }),
        ...mapGetters('user',['companyAuth','companyObj','auth','profilePicture'])
    }
}

