/*
* @Author: 曹俊杰
* @Date: 2024-09-18 18:16:42
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-09-30 01:59:12
* 首页面板
*/
<template>
    <div class="panel">
        <div class="panel-left">
            <el-avatar :size="60" :src="profilePicture"></el-avatar>
            <div class="panel-left-content">
                <div class="username">
                    <p>
                        {{ user.name || '暂无信息' }}
                    </p>
                    <renzheng-icon :value="auth"></renzheng-icon>
                </div>
                <div class="phone">账号：{{ user.phone }}</div>
            </div>
        </div>
        <div class="panel-right">
            <div class="item">
                <div class="item-top">
                    <iconpark-icon width="18px" height="18px" color="#fff" name="qiyeyue"></iconpark-icon>
                    <p>企业余额</p>
                </div>
                <div class="item-bottom" @click="$router.push({
                    name:'recharge',
                    params: {
                        type:'chongzhi'
                    }

                })">
                    <span class="value">{{ balance | priceFormat(2) }}</span>
                    <span>充值</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 4 7" fill="none">
                        <path
                            d="M3.84489 3.14017C4.05171 3.33882 4.0517 3.66087 3.84489 3.85951L0.904034 6.68419C0.697225 6.88283 0.361928 6.88283 0.15511 6.68419C-0.0517074 6.48554 -0.0516989 6.16349 0.15511 5.96485L2.72151 3.49984L0.15511 1.03482C-0.0517077 0.836177 -0.0516991 0.514126 0.15511 0.315487C0.361919 0.116847 0.697216 0.116839 0.904034 0.315487L3.84489 3.14017Z"
                            fill="white" />
                    </svg>
                </div>
            </div>
            <div class="line"></div>
            <div class="item">
                <div class="item-top">
                    <iconpark-icon width="18px" height="18px" color="#fff" name="wodedaijinquan"></iconpark-icon>
                    <p>代金券</p>
                </div>
                <div class="item-bottom">
                    <span class="value">3</span>
                    <span>查看</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 4 7" fill="none">
                        <path
                            d="M3.84489 3.14017C4.05171 3.33882 4.0517 3.66087 3.84489 3.85951L0.904034 6.68419C0.697225 6.88283 0.361928 6.88283 0.15511 6.68419C-0.0517074 6.48554 -0.0516989 6.16349 0.15511 5.96485L2.72151 3.49984L0.15511 1.03482C-0.0517077 0.836177 -0.0516991 0.514126 0.15511 0.315487C0.361919 0.116847 0.697216 0.116839 0.904034 0.315487L3.84489 3.14017Z"
                            fill="white" />
                    </svg>
                </div>
            </div>
            <div class="line"></div>
            <div class="item">
                <div class="item-top">
                    <iconpark-icon width="18px" height="18px" color="#fff" name="tuiguangshouyi"></iconpark-icon>
                    <p>推广收益</p>
                </div>
                <div class="item-bottom">
                    <span class="value">369</span>
                    <span>提现</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 4 7" fill="none">
                        <path
                            d="M3.84489 3.14017C4.05171 3.33882 4.0517 3.66087 3.84489 3.85951L0.904034 6.68419C0.697225 6.88283 0.361928 6.88283 0.15511 6.68419C-0.0517074 6.48554 -0.0516989 6.16349 0.15511 5.96485L2.72151 3.49984L0.15511 1.03482C-0.0517077 0.836177 -0.0516991 0.514126 0.15511 0.315487C0.361919 0.116847 0.697216 0.116839 0.904034 0.315487L3.84489 3.14017Z"
                            fill="white" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import  userinfo  from "@/mixins/userinfo.js"
import renzhengIcon from "@/components/renzhengIcon.vue"
export default {
    components: {
        renzhengIcon
    },
    mixins: [userinfo],
}
</script>

<style lang="scss" scoped>
.panel {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
        display: flex;
        align-items: center;

        &-content {
            margin-left: 20px;

            .username {
                font-weight: 400;
                font-size: 20px;
                color: #fff;
                display: flex;
                align-items: center;
                font-size: 18px;
                font-style: normal;
            }

            .phone {
                opacity: 0.5;
                color: #fff;
                font-size: 14px;
                margin-top: 10px;
            }
        }
    }

    &-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        .item {
            height: 65px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 10px;
            color: #fff;

            &-top {
                display: flex;
                align-items: flex-end;
                gap: 6px;
                font-size: 14px;
                font-style: normal;
                font-weight: 350;
                line-height: normal;
            }

            &-bottom {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                font-size: 12px;
                font-style: normal;
                font-weight: 350;
                line-height: normal;
                cursor: pointer;

                .value {
                    font-size: 28px;
                }
            }
        }

        .line {
            width: 1px;
            height: 26px;
            opacity: 0.5;
            background: #FFF;
            margin: 0 70px;

        }
    }
}
</style>
