<template>
    <div class="box">
        <div class="title">合同保</div>
        <div class="content">
            <div class="item">
                <div class="label">未保障</div>
                <div class="value">{{ item.unprotectedCount }}</div>
            </div>
            <div class="item">
                <div class="label">保障中</div>
                <div class="value">{{ item.protectedCount }}</div>
            </div>
            <div class="item">
                <div class="label">已到期</div>
                <div class="value">{{ item.expiredCount }}</div>
            </div>
            <div class="item">
                <div class="label">执行中</div>
                <div class="value">{{ item.insuranceInProcessCount }}</div>
            </div>
            <div class="item">
                <div class="label">已完结</div>
                <div class="value">{{ item.terminatedCount }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            item: state => state.electronic.contractProfile
        }),
    }
}
</script>

<style lang="scss" scoped>
.box {
    height: 165px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #fff;

    .title {
        line-height: 60px;
        flex-shrink: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        padding: 0 20px;
    }

    .content {
        display: flex;

        .item {
            width: 20%;
            height: 85px;
            flex-shrink: 0;
            border-radius: 4px;
            padding: 10px 20px;
            padding-right: 10px;

            .label {
                color: #666;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .value {
                font-family: "DIN Alternate";
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-top: 10px;
            }
        }
    }

}
</style>