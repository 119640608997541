/*
* @Author: 曹俊杰
* @Date: 2024-09-28 10:58:19
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-09-30 00:16:19
*/归档选择框

<template>
    <el-dialog title="归档选择文件夹" :visible.sync="dialogVisible" width="350px" @closed="closed">
        <div style="padding:20px;">
            <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
                <el-row :gutter="30">
                    <el-col :span="24">
                        <el-form-item prop="comments">
                            <el-cascader style="width: 100%;" :props="{value:'dirId',emitPath:false}" :options="list" v-model="form.dirId" :show-all-levels="false"></el-cascader>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close" size="mini">取 消</el-button>
            <el-button type="primary" @click="submit" size="mini">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
    components: {
    },
    computed: {
        ...mapState('documents', ['archiveList']),
        list() {
            let alist = this.archiveList;
            alist.forEach(res => {
                if (res.children) {
                    res.children.forEach(re => {
                        Reflect.deleteProperty(re, "children");
                    })
                }
            })
            return alist
        }
    },
    data() {

        return {
            dialogVisible: false,
            form: {
                dirId: null,   //目录ID
                contractId: null,   //合同ID
                docId: null    //文档ID
            },

        };
    },
    mounted() { },
    methods: {
        change(res){
            console.log(res)
        },
        show(data) {
            console.log(data)
            let form = this.$utils.deepClone(data);
            this.form.contractId = form.contractId;
            this.form.docId = form.docId;
            this.form.dirId = form.dirId;
            this.dialogVisible = true;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (this.form.docId) {   //  如果当前有文档id  走文件更换目录接口   如果没有，走归档接口
                        this.putgenghuanmulu()
                    } else {
                        this.putguidiang()
                    }

                } else {
                    return false
                }
            })
        },
        close() {
            this.dialogVisible = false
        },
        closed() {
            this.form = {
                comments: '',
                applicationUuid: null
            }
        },
        // 文件归档
        async putguidiang() {
            const params = {
                dirId: this.form.dirId,
                contractId: this.form.contractId
            }
            const { status, data, info } = await this.$api.putguidiang(params)
            if (status === 200) {
                this.$message({
                    type: 'success',
                    message: info
                })
                this.$emit('success')
                this.dialogVisible = false;
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        },
        // 文件更换目录
        async putgenghuanmulu() {
            const params = {
                docId: this.form.docId,
                dirId: this.form.dirId
            }
            const { status, data, info } = await this.$api.putgenghuanmulu(params)
            if (status === 200) {
                this.$message({
                    type: 'success',
                    message: info
                })
                this.$emit('success')
                this.dialogVisible = false;
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        },
    },
};
</script>
<style lang='scss' scoped>

</style>