/*
* @Author: 曹俊杰
* @Date: 2024-10-11 11:53:19
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-10-11 12:34:34
*/购买菜单
<template>
    <div class="dialog">
        <el-dialog :visible.sync="dialogVisible" width="30%" title="订单支付" @close="close">
            <div class="dialog__title">订单信息</div>
            <div class="dialog__item">
                <div class="dialog__item__l">{{ detail.name }}：</div>
                <div class="dialog__item__r">¥ {{ detail.price }}</div>
            </div>
            <div class="dialog__item">
                <div class="dialog__item__l">总金额：</div>
                <div class="dialog__item__r blue">¥ {{ detail.price }}</div>
            </div>
            <div class="dialog__title" style="margin-top: 20px;">支付方式</div>
            <!-- <div class="dialog__item">
            <div class="dialog__item__l">法务知识（XXX课程）：</div>
            <div class="dialog__item__r">¥ 100.00</div>
        </div> -->
            <div class="dialog__item">
                <div class="dialog__item__l">余额支付（企业余额：{{ detail.balance }}元）：</div>
                <div class="dialog__item__r red">¥ {{ detail.price }}</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini">取 消</el-button>
                <el-button type="primary" @click="putCourseBuy" size="mini">确 定</el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
            detail: {
                name: '',
                price: null,
                balance: null,
                courseId: null,
            }
        }
    },
    methods: {
        show({ name, price, balance, courseId }) {
            console.log(name)
            this.detail = {
                name, price, balance, courseId
            }
            this.dialogVisible = true;
        },
        close() {
            this.dialogVisible = false
        },
        async putCourseBuy() {
            const { courseId } = this.detail
            const { status, info } = await this.$api.putCourseBuy({ courseId });
            if (status == 200) {
                this.$emit("success");
                this.$message({
                    type: "success",
                    message: info
                })
                this.close()
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog {
    .blue {
        color: #0062FF;
    }

    .red {
        color: #FF0D0D;
    }

    /deep/.el-dialog {
        background: #F3F6F8
    }

    &__title {
        color: #262626;
        font-family: "Source Han Sans CN";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        background: #fff;
        padding: 0 16px;

    }

    &__item {
        color: #262626;
        font-family: "Source Han Sans CN";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__l {}

        &__r {}
    }
}
</style>
