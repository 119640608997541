const state = {
	unreadList: []
}
const mutations = {
	setUnreadList (state, payload) {
		state.unreadList = payload
	}

}
const getters = {
	isDot(state) {
		return state.unreadList.length?true:false
	}
}
const actions = {
	setUnreadList ({ commit },payload) {
		commit('setUnreadList', payload)
	},
    clearUnreadList({ commit }){
        commit('setUnreadList', [])
    }
}
export default {
	namespaced: true,
	state,
	actions,
	mutations,
    getters
}