/*
* @Author: 曹俊杰
* @Date: 2023-04-22 22:11:44
* @Module: 分类表单
*/
<template>
  <el-dialog :title="type == 'add' ? '添加目录' : type == 'revision' ? '修改目录' : ''" :visible.sync="dialogVisible"
    width="800px" @closed="closed">
    <div style="padding:20px;">
      <el-form size="mini" label-position="top" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="目录名称" prop="item">
              <el-input v-model="form.label" placeholder="请输入目录名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button size="mini" type="danger" v-show="type == 'revision'" @click="ondelete">删除</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {

  data() {

    return {
      type: 'add',
      dialogVisible: false,
      dirId: null,
      parentId: null,
      form: {
        label: ''
      }
    };
  },
  mounted() { },
  methods: {
    show({ type = 'add', data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.dirId = data.dirId;
      this.type = type;
      this.dialogVisible = true
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (this.type == "delete") {
          this.onsubmit()
        } else {
          if (valid) {
            this.onsubmit()
          } else {
            return false
          }
        }
      })
    },
    close() {
      this.dialogVisible = false
    },
    closed() {
      this.form = {
        label: ''
      }
      this.dirId = null;
      this.parentId = null
    },
    async ondelete() {
      const { status, info } = await this.$api.deleteArchiveDir({ dirId: this.dirId });
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success')
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    async onsubmit() {
      let api = {
        revision: {
          func: this.$api.putChangeArchiveDir,
          params: {
            ...this.form
          }
        },
        add: {
          func: this.$api.putArchiveDir,
          params: {
            ...this.form
          }
        }
      }
      if (this.type === 'add' && this.dirId) {
        api.add.params.parentId = this.dirId
      }
      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  },
};
</script>
<style lang='scss' scoped></style>