/* 
 * @Author: 曹俊杰 
 * @Date: 2022-04-13 20:49:56
 * @Module: 合同文件
 */
import api from '@/utils/api'
const state = {
	archiveList: [{ asd: 111 }]
}
const mutations = {
	setHelpList(state, payload) {
		state.helpList = payload
	},
	setArchiveList(state, payload) {
		state.archiveList = payload;
	}
}
const actions = {
	async getFile(_, { callback, fileId }) {
		const { status, data } = await api.getFile({ fileId })
		if (status === 200) {
			// window.location.href = data.downloadUrl;
			// window.open(data.downloadUrl)
			callback && callback(data)
		}
	},
	async getDocFile(_, { callback, uuid }) {
		const { status, data } = await api.getDocFile({ uuid })
		if (status === 200) {
			callback && callback(data)
		}
	},

	//  获取档案目录列表
	async getArchiveList({ commit }, params={}) {
		const { status, data } = await api.getArchiveList(params)
		if (status === 200) {
			commit('setArchiveList', data);
		}
	}

}
export default {
	namespaced: true,
	state,
	actions,
	mutations
}