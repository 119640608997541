import Router from '@/router'
import api from '@/utils/api'
import { MessageBox, Message } from 'element-ui';

import heada_Image from "@/static/profile_picture.png"
const state = {
	user: {
		auth: false,
		canTakeEvidence: false,
		company: null,
		creator: false,
		deletedAt: null,
		idNumber: null,
		inviteCode: "",
		name: null,
		phone: "",
		token: "",
		uuid: "",
	},
	balance: 0,   //余额
	beans: 0,

}
const getters = {
	//个人认证状态
	auth(state) {
		return state.user.auth
	},
	// 公司认证状态
	companyAuth(state) {
		return !!state.user.company
	},
	profilePicture() {
		return heada_Image
	},
	// 公司信息
	companyObj(state) {
		if (state.user.company) {
			return state.user.company
		} else {
			return {
				companyId: null,
				name: '',
				IdNumber: '',
				representativeName: '',
				representativeIdNumber: '',
				representativePhone: '',
				searchConformed: null,
			}
		}
	}
}
const mutations = {
	setUserInfo(state, payload) {
		state.user = {
			...state.user,
			...payload
		};
		// state.commit("userLog/saveUserLog", state.user)
	},
	setData(state, { key, value }) {
		state[key] = value
	},
	logout(state) {
		state.user = {
			auth: false,
			canTakeEvidence: false,
			company: null,
			creator: false,
			deletedAt: null,
			idNumber: null,
			inviteCode: "",
			name: null,
			phone: "",
			token: "",
			uuid: "",
		}
		Router.push({
			path: '/login', query: { redirect: Router.path }
		})
		window.clearVuexAlong();
	}

}
const actions = {
	async getAccount({ commit }, callback) {
		const { status, data: { token, ...data } } = await api.getAccount()
		if (status === 200) {
			if (data.companyUuid) {
				commit('setUserInfo', data)
			}
			callback && callback(data)
		}
	},
	async getCompanyBalance({ commit, state }) {     //获取公司余额  ///api/company/{companyId}/balance
		const { status, data } = await api.getCompanyBalance({ companyUuid: state.user.company?.uuid })
		if (status === 200) {
			commit('setData', { key: 'balance', value: data.coins });
			commit('setData', { key: 'beans', value: data.beans });
		}
	},
	async getSearchConformed({ commit, state }, { inviteCode, callback }) {
		const { status, info } = await api.putSearchConform({ companyUuid: state.user.companyUuid, inviteCode })
		if (status === 200) {
			Message({
				type: 'success',
				message: info
			})
			commit('setUserInfo', {
				searchConformed: true
			})
			callback && callback({ status, info })
		} else {
			Message({
				type: 'error',
				message: info
			})
		}

	},
	// // 获取我的公司列表
	// async getCompanyMine ({ commit, state }) {
	// 	const { status, data } = await api.getCompanyMine()
	// 	if (status === 200) {
	// 		commit('setData', { key: 'companyList', value: data.list });
	// 	}
	// },
	// 切换用户
	changeUserInfo({ dispatch, commit }, params) {
		commit('setUserInfo', params);
		location.reload();
		// dispatch('getCompanyBalance');
	},
	toAccountAuth() {
		Router.push({ name: 'UserAuth' })
	},
	toAccountCompanyAuth({ state, dispatch }) {
		const { name } = state.user
		if (name) {
			Router.push({ name: 'CompanyAuth' })
		} else {
			MessageBox.confirm('企业实名前先进行个人实名, 是否继续?', '提示', {
				confirmButtonText: '去个人实名',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				dispatch('toAccountAuth')
			})
		}
	},
	setUserInfo({ commit, state, dispatch }, params) {
		commit('setUserInfo', params);
		dispatch('userLog/saveUserLog', state.user, { root: true })
	},
	buy({ state }, price) {
		const { balance } = state;
		return new Promise((resolve, reject) => {
			if (price < balance) {
				resolve(balance)
			} else {
				MessageBox.confirm('企业余额不足，请充值后再进行购买。', '提示', {
					confirmButtonText: '前去充值',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					Router.push({
						name: 'recharge',
						params: {
							type: 'chongzhi'
						}
					})
				})
				reject(balance)
			}
		})

	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}