/*
* @Author: 曹俊杰
* @Date: 2024-09-22 23:04:23
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-09-25 10:59:28
*/认证图标
<template>
    <div class="icon" :class="value ? 'success' : 'error'" @click="click"></div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'auth'
        }

    },
    methods: {
        click() {
            if (!this.value) {
                if (this.type == 'auth') {
                    this.$store.dispatch('user/toAccountAuth')
                } else if (this.type == 'companyAuth') {
                    this.$store.dispatch('user/toAccountCompanyAuth')
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.icon {
    width: 60px;
    height: 20px;
    margin-left: 6px;
}

.error {
    background-image: url(~@/static/image/weirenzheng.png);
    background-size: 100%;
}

.success {
    background-image: url(~@/static/image/yirenzheng.png);
    background-size: 100%;
}
</style>