<template>
    <div class="box">
        <div class="title">
            <div>帮助中心</div>
            <span>查看更多</span>
        </div>
        <div class="content">
            <div class="item">
                <span>1.如何进行个人实名认证？</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                    <path
                        d="M1.27364 0.404932C1.04174 0.179473 0.672542 0.179473 0.440641 0.404932C0.199315 0.639555 0.199316 1.02711 0.440642 1.26174L4.28571 5L0.440641 8.73827C0.199315 8.97289 0.199316 9.36045 0.440641 9.59507C0.672542 9.82053 1.04174 9.82053 1.27364 9.59507L6 5L1.27364 0.404932Z"
                        fill="#999999" />
                </svg>
            </div>
            <div class="item">
                <span>2.如何进行企业实名认证？？</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                    <path
                        d="M1.27364 0.404932C1.04174 0.179473 0.672542 0.179473 0.440641 0.404932C0.199315 0.639555 0.199316 1.02711 0.440642 1.26174L4.28571 5L0.440641 8.73827C0.199315 8.97289 0.199316 9.36045 0.440641 9.59507C0.672542 9.82053 1.04174 9.82053 1.27364 9.59507L6 5L1.27364 0.404932Z"
                        fill="#999999" />
                </svg>
            </div>
            <div class="item">
                <span>3.电子合同的法律依据是什么？</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                    <path
                        d="M1.27364 0.404932C1.04174 0.179473 0.672542 0.179473 0.440641 0.404932C0.199315 0.639555 0.199316 1.02711 0.440642 1.26174L4.28571 5L0.440641 8.73827C0.199315 8.97289 0.199316 9.36045 0.440641 9.59507C0.672542 9.82053 1.04174 9.82053 1.27364 9.59507L6 5L1.27364 0.404932Z"
                        fill="#999999" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.box {
    background: #fff;
    padding: 0 20px;
    padding-bottom: 10px;

    .title {
        line-height: 60px;
        flex-shrink: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        display: flex;
        justify-content: space-between;

        span {
            color: #999;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-weight: 400;
        }
    }

    .content {
        .item {
            display: flex;
            height: 50px;
            justify-content: space-between;
            align-items: center;
            color: #666;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
        }
    }
}
</style>