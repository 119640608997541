import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue' // 主页
import ElectronicDocuments from '@/views/ElectronicDocuments.vue'  // 电子文书
import AccurateEvidence from '@/views/AccurateEvidence.vue'  // 精准取证
import ArchiveManagement from '@/views/ArchiveManagement.vue'  // 归档管理
import ArchiveShare from '@/views/ArchiveShare.vue'  // 档案分享
import ArchiveManagementDetail from '@/views/ArchiveManagement/ArchiveManagementDetail.vue'  // 归档管理详情
import CaseProgress from '@/views/CaseProgress.vue'  // 案件进度
import AccountManagement from '@/views/AccountManagement.vue'  // 账号管理
// import SealManagement from '@/views/SealManagement.vue'  // 印章管理
import AccountCenter from '@/views/AccountCenter.vue'  // 账户中心
// import ExpenseCenter from '@/views/VoucherCenter.vue'  // 费用中心

import InstituteJustice from '@/views/InstituteJustice.vue'  // 法务学院
import Help from '@/views/Help.vue'  // 帮助中心
import Notice from '@/views/Notice.vue'  // 公告
import Inspect from '@/views/Inspect.vue'  // 三要素核查
import Search from '@/views/Search.vue'  // 三要素核查
import Exposure from '@/views/Exposure.vue'  // 曝光平台
import Login from '@/views/Login.vue'  // 登录
import defaultLayouts from '@/layouts/default'
import store from '../store'
import { Message } from 'element-ui';
import im from "@/utils/im"
Vue.use(VueRouter)
// 重复跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		component: defaultLayouts,
		redirect: '/home',
		children: [
			{
				path: '/home',
				name: 'home',
				meta: {
					menu: 'home',
					title: '工作台',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Home
			},

			{
				path: '/course',
				name: 'course',
				meta: {
					hidMenu: true
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Course'),
				children: [

					{
						meta: {
							hidMenu: true
						},
						path: '/course/:courseId',
						name: 'courseDetail',
						component: () => import('@/views/Course/detail'),
					}
				]
			},

			{
				path: '/electronicDocumentsList',
				name: 'electronicDocumentsList',
				meta: {
					requireAuth: true,
					menu: 'electronicDocumentsList',
					title: '电子合同',
				},
				redirect: '/electronicDocumentsList/0',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: ElectronicDocuments,
				children: [
					{
						path: '/electronicDocumentsList/:dirId',
						name: 'electronicDocumentsList',
						meta: {
							requireAuth: true,
							menu: 'electronicDocumentsList',
							title: '电子合同列表',
						},
						component: () => import('@/views/ElectronicDocuments/list'),
					}
				]
			},
			{
				path: '/search',
				name: 'search',
				meta: {
					requireAuth: true,
					menu: 'search',
					title: '查询小号',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Search
			},
			{
				path: '/search/searchForm',
				name: 'searchForm',
				meta: {
					requireAuth: true,
					menu: 'search',
					title: '查询小号',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Search/SearchForm'),
			},
			{
				path: '/search/searchConformed',
				name: 'searchConformed',
				meta: {
					requireAuth: true,
					menu: 'search',
					title: '查询小号',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Search/searchConformed'),
			},
			{
				path: '/accurateEvidence',
				name: 'accurateEvidence',
				meta: {
					menu: 'accurateEvidence',
					title: '精准取证',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: AccurateEvidence,
				redirect: '/accurateEvidence/extension',
				children: [
					{
						path: '/accurateEvidence/extension',
						name: 'extension',
						meta: {
							menu: 'extension',
							title: '签署出证',
						},
						component: () => import('@/views/AccurateEvidence/Extension'),

					}, {
						path: '/accurateEvidence/extension/apply',
						name: 'apply',
						meta: {
							menu: 'apply',
							title: '申请出证',
						},
						component: () => import('@/views/AccurateEvidence/Apply'),
					},

					{
						path: '/accurateEvidence/record',
						name: 'record',
						meta: {
							menu: 'record',
							title: '出证记录',
						},
						component: () => import('@/views/AccurateEvidence/Record'),
					},
				]
			},

			{
				path: '/equity',
				name: 'equity',
				meta: {
					menu: 'equity',
					title: '权益执行',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Equity'),
				redirect: '/equity/execute',
				children: [
					{
						path: '/equity/execute',
						name: 'Execute',
						meta: {
							menu: 'execute',
							title: '权益执行',
						},
						component: () => import('@/views/Equity/Execute'),

					}, {
						path: '/equity/result',
						name: 'result',
						meta: {
							menu: 'result',
							title: '结束案件',
						},
						component: () => import('@/views/Equity/Result'),
					},


				]
			},




			{
				path: '/archiveShare',
				name: 'archiveShare',
				meta: {
					menu: 'archiveManagement',
					title: '归档管理',
				},
				component: ArchiveShare,
			},
			{
				path: '/archiveManagement',
				name: 'archiveManagement',
				meta: {
					menu: 'archiveManagement',
					title: '归档管理',
				},
				redirect: '/archiveManagement/0',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: ArchiveManagement,
				children: [
					{
						path: '/archiveManagement/:id',
						component: ArchiveManagementDetail,
						meta: {
							menu: 'archiveManagement',
							title: '归档管理',
						},
					}
				]

			},
			{
				path: '/caseProgress',
				name: 'caseProgress',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: CaseProgress,
				meta: {
					menu: 'caseProgress'
				},
			},
			{
				path: '/exposure',
				name: 'exposure',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Exposure,
				meta: {
					menu: 'exposure',
					title: '曝光列表'
				},
				redirect: '/exposure/all',
				children: [
					{
						path: '/exposure/all',
						component: () => import('@/views/Exposure/All'),
						meta: {
							menu: 'exposure',
							hiddenFooter: true,
							title: '曝光列表'
						},
						name: 'all'
					},
					{
						path: '/exposure/mine',
						component: () => import('@/views/Exposure/Mine'),
						meta: {
							menu: 'exposure',
							hiddenFooter: true,
							title: '曝光列表'
						},
						name: 'mine'
					}
				]
			},
			{
				path: '/inspect',
				name: 'inspect',
				// redirect: '/inspect/inspectSearch',
				meta: {
					menu: 'inspect',
					title: '三要素核查'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Inspect,
				redirect: '/inspect/inspectHome',
				children: [

					{
						path: '/inspect/inspectList',
						name: 'inspectList',
						meta: {
							menu: 'inspectList',
							title: '排查记录'
						},
						// route level code-splitting
						// this generates a separate chunk (about.[hash].js) for this route
						// which is lazy-loaded when the route is visited.
						component: () => import('@/views/Inspect/InspectList')
					},
					{
						path: '/inspect/inspectHome',
						name: 'inspectHome',
						meta: {
							menu: 'inspectHome',
							title: '风险排查'
						},
						// route level code-splitting
						// this generates a separate chunk (about.[hash].js) for this route
						// which is lazy-loaded when the route is visited.
						component: () => import('@/views/Inspect/InspectHome')
					},
				]
			},

			{
				path: '/enterprise',
				name: 'enterprise',
				// redirect: '/inspect/inspectSearch',
				meta: {
					menu: 'Enterprise',
					title: '企业管理'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Enterprise'),
				redirect: '/enterprise/accountColleague',
				children: [

					{
						path: '/enterprise/accountColleague',
						name: 'accountColleague',
						meta: {
							menu: 'accountColleague',
							title: '员工管理',
						},
						component: () => import('@/views/Enterprise/AccountColleague'),
					},
					{
						path: '/enterprise/sealManagement',
						name: 'sealManagement',
						meta: {
							menu: 'sealManagement',
							title: '印章管理',
						},
						component: () => import('@/views/Enterprise/SealManagement'),
					},
					{
						path: '/enterprise/SealManagementDetail/:sealId',
						name: 'sealManagementDetail',
						meta: {
							menu: 'sealManagement',
							title: '印章管理',
						},
						component: () => import('@/views/Enterprise/SealManagementDetail')
					},
				]
			},

			{
				path: '/inspect/inspectSearch',
				name: 'inspiectSearch',
				meta: {
					menu: 'inspect',
					title: '三要素核查'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Inspect/InspectSearch')
			},
			{
				path: '/inspect/inspectResult',
				name: 'inspectResult',
				meta: {
					menu: 'inspect',
					title: '三要素核查'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Inspect/InspectResult')
			},
			{
				path: '/accountManagement',
				name: 'accountManagement',
				meta: {
					menu: 'accountManagement',
					title: '个人中心'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: AccountManagement,
				redirect: '/accountManagement/personal',
				children: [
					{
						path: '/accountManagement/personal',
						component: () => import('@/views/AccountManagement/Personal'),
						meta: {
							menu: 'accountManagement',
							title: '个人中心'
						},
						name: 'personal'
					},
					{
						path: '/accountManagement/sealManagement',
						component: () => import('@/views/AccountManagement/SealManagement'),
						meta: {
							menu: 'accountManagement',
							title: '个人中心'
						},
						name: 'sealManagement'
					}
				]
			},
			{
				path: '/accountManagement/accountAuth',    //个人认证
				name: 'accountAuth',
				meta: {
					menu: 'accountManagement',
					title: '个人中心'
				},
				component: () => import('@/views/AccountManagement/AccountAuth'),


			},
			{
				path: '/accountManagement/accountCompanyAuth',    //企业认证
				name: 'accountCompanyAuth',
				meta: {
					menu: 'accountManagement',
					title: '个人中心'
				},
				component: () => import('@/views/AccountManagement/AccountCompanyAuth')
			},
			{
				path: '/accountCenter',
				name: 'accountCenter',
				component: AccountCenter,
				redirect: '/accountCenter/balance',
				meta: {
					menu: 'accountCenter',
					title: '账户中心'
				},
				children: [
					{
						path: '/accountCenter/balance',
						name: 'balance',
						redirect: '/accountCenter/balance/recharge',
						meta: {
							menu: 'balance',
							title: '企业余额'
						},
						component: () => import('@/views/AccountCenter/Balance'),
						children: [
							{
								path: '/accountCenter/balance/expense',
								name: 'expense',
								meta: {
									menu: 'expense',
									title: '消费记录'
								},
								component: () => import('@/views/AccountCenter/Balance/Expense'),
							},
							{
								path: '/accountCenter/balance/recharge',
								name: 'recharge',
								meta: {
									menu: 'recharge',
									title: '充值记录'
								},
								component: () => import('@/views/AccountCenter/Balance/Recharge'),
							},
						]
					},
					{
						path: '/accountCenter/voucher',
						name: 'voucher',
						meta: {
							menu: 'voucher',
							title: '代金券'
						},
						component: () => import('@/views/AccountCenter/Voucher'),
					},
					{
						path: '/accountCenter/promotion',
						name: 'promotion',
						meta: {
							menu: 'promotion',
							title: '消费记录'
						},
						component: () => import('@/views/AccountCenter/Promotion'),
					},
				],

			},
			{
				path: '/expenseCenter/cashier',
				name: 'cashier',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/VoucherCenter/Cashier'),
				meta: {
					menu: 'expenseCenter',
					title: '费用中心'
				},
				children: [
					{
						path: '/expenseCenter/cashier/gold',
						name: 'gold',
						// route level code-splitting
						// this generates a separate chunk (about.[hash].js) for this route
						// which is lazy-loaded when the route is visited.
						component: () => import('@/views/VoucherCenter/Cashier/Gold'),
						meta: {
							menu: 'expenseCenter',
							title: '费用中心'
						},
					},
					{
						path: '/expenseCenter/cashier/coupon',
						name: 'coupon',
						// route level code-splitting
						// this generates a separate chunk (about.[hash].js) for this route
						// which is lazy-loaded when the route is visited.
						component: () => import('@/views/VoucherCenter/Cashier/Coupon'),
						meta: {
							menu: 'expenseCenter',
							title: '费用中心'
						},
					}
				]
			},
			{
				path: '/instituteJustice',
				name: 'instituteJustice',
				meta: {
					menu: 'instituteJustice',
					keepAlive: true,
					ifDoFresh: false,
					title: '法务学院'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: InstituteJustice
			},
			{
				path: '/instituteJustice/:id',
				name: 'instituteJusticeDetail',
				meta: {
					menu: 'instituteJustice',
					title: '法务学院'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/InstituteJustice/InstituteJusticeDetail')
			},
			{
				path: '/help',
				name: 'help',
				meta: {
					menu: 'help',
					title: '帮助中心'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Help,
				children: [
					// {
					// 	path: '',
					// 	component: () => import('@/views/Help/HelpDefault'),
					// 	meta: {
					// 		menu: 'help'
					// 	},
					// },
					{
						path: '/help/:id',
						component: () => import('@/views/Help/HelpDetail'),
						meta: {
							menu: 'help',
							title: '帮助中心'
						},
					}
				]
			},
			{
				path: '/notice',
				name: 'notice',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Notice,
				meta: {
					menu: 'notice',
					title: '平台公告'
				}
			},
			{
				path: '/notice/:id',
				component: () => import('@/views/Notice/NoticeDetail'),
				meta: {
					menu: 'notice',
					title: '平台公告'
				},
			},

		]
	},
	{
		//实名认证
		path: '/auth',
		component: () => import('@/layouts/auth'),
		redirect: '/auth/CompanyAuth',
		meta: {
			hidMenu: true
		},
		children: [{
			path: '/auth/UserAuth',
			name: 'UserAuth',
			meta: {
				hidMenu: true
			},
			component: () => import('@/views/UserAuth')
		},
		{
			path: '/auth/CompanyAuth',
			name: 'CompanyAuth',
			meta: {
				hidMenu: true
			},
			component: () => import('@/views/CompanyAuth')
		}]
	},
	{ //空白页
		path: '/blank',
		component: () => import('@/layouts/blank'),
		children: [
			{
				path: '/equity/equityFrom',
				name: 'equityFrom',
				meta: {
					menu: 'equityFrom',
					title: '权益执行表单',
				},
				component: () => import('@/views/Equity/EquityFrom'),
			},
		]

	},
	{
		component: () => import('@/layouts/electronicDocuments'),
		path: '',
		children: [
			{
				path: '/electronicDocuments/electronicDocumentsPDF',
				name: 'electronicDocumentsPDF',
				meta: {
					menu: 'electronicDocuments'
				},
				component: () => import('@/views/ElectronicDocuments/ElectronicDocumentsPDF')
			},
			{
				path: '/electronicDocuments/contractInsurance',
				name: 'contractInsurance',
				component: () => import('@/views/ContractInsurance')
			},
			{
				path: '/electronicDocuments/electronicDocumentsAdd',
				name: 'electronicDocumentsAdd',
				meta: {
					menu: 'electronicDocuments'
				},
				component: () => import('@/views/ElectronicDocuments/ElectronicDocumentsAdd')
			},
			{
				path: '/electronicDocuments/electronicDocumentsSuccess',
				name: 'electronicDocumentsSuccess',
				meta: {
					menu: 'electronicDocuments'
				},
				component: () => import('@/views/ElectronicDocuments/ElectronicDocumentsSuccess')
			},
			{
				path: '/companyProfile',
				name: 'companyProfile',
				component: () => import('@/views/CompanyProfile')
			},
			{
				path: '/exposure/form',
				name: 'exposureForm',
				component: () => import('@/views/Exposure/Form')
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '*',
		name: 'error_404',
		component: () => import('@/views/404'),
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	linkActiveClass: 'active',
	routes
})
router.beforeEach((to, from, next) => {
	const { token } = store.state.user.user
	if (to.matched.some(record => record.meta.requireAuth)) {  // 判断该路由是否需要登录权限
		if (token) {  // 判断当前的token是否存在
			next();
		}
		else {
			next({
				path: '/login',
				query: { redirect: to.fullPath }  // 将跳转的路由path作为参数，登录成功后跳转到该路由
			})
		}
	}
	else {
		next();
	}




	// if (['expenseCenter', 'electronicDocuments', 'exposure', 'archiveManagement', 'inspectSearch', 'inspect', 'sealManagement', 'accountColleague'].includes(to.meta.menu)) {
	// 	const { company, creator } = store.state.user.user
	// 	if (company) {
	// 		if (['accountColleague', 'sealManagement'].includes(to.meta.menu)) {
	// 			if (creator) {
	// 				next()
	// 			} else {
	// 				Message({
	// 					message: '此功能需要法人账号操作',
	// 					type: 'warning'
	// 				})
	// 			}
	// 		} else {
	// 			next()
	// 		}
	// 	} else {
	// 		next({
	// 			path: '/auth/CompanyAuth'
	// 		})
	// 		Message({
	// 			message: '此功能需加入/创建企业',
	// 			type: 'warning'
	// 		})
	// 	}
	// } else {
	// 	next()
	// }
	// next()
})
router.afterEach((to, from) => {
	im.update()
})
export default router
