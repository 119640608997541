<template>
    <div class="box">
        <div class="title">
            法务知识
        </div>
        <courseList />
    </div>
</template>

<script>
import courseList from "@/views/Course/components/courseList"
export default {
    components: {
        courseList
    }
}
</script>

<style lang="scss" scoped>
.box {
    background: #fff;
    padding: 0 20px;
    border-radius: 4px;

    .title {
        line-height: 60px;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
    }


}
</style>