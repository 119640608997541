/*
* @Author: 曹俊杰
* @Date: 2024-09-25 12:03:11
* @Last Modified by: 曹俊杰
* @Last Modified time: 2024-09-30 00:21:35
*/合同菜单
<template>
    <el-tooltip :disabled="disabled" content="右键操作菜单" placement="bottom">
        <div class="menu">
            <div class="item1 item " v-for="item in sysList"
                :class="{ active: (parmId == item.dirId) && activeItemNull }" :key="item.dirId" @click="itmClick(item)">
                <i :class="item.icon"></i>
                <span>{{ item.label }}</span>
            </div>
            <div v-for="item in archiveList" :key="item.dirId">
                <div class="item2 item" @click="itemClick(item)" :class="{ active: isActive(item) }"
                    @contextmenu.prevent="onRightClick(item)">
                    <div>
                        <i class="iconfont el-icon-ali-zidingyihetongfenlei"></i>
                        <span>{{ item.label }}</span>
                    </div>
                    <b :class="isActive(item) ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></b>
                </div>
                <div v-for="itm in item.children" :key="itm.dirId" :class="{ fold: !isActive(item) }">
                    <div class="item3 item " @contextmenu.prevent="onRightClick(itm)"
                        :class="{ blue: parmId == itm.dirId }" @click="itmClick(itm)">
                        <i style="font-size: 30px;color:#aaa;">·</i>
                        <span>{{ itm.label }}</span>
                    </div>
                </div>
            </div>
            <dirForm ref="dirForm" @success="getArchiveList" />
        </div>
    </el-tooltip>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dirForm from "@/views/ElectronicDocuments/components/dirForm.vue";
export default {
    components: {
        dirForm
    },
    computed: {
        ...mapState('documents', ['archiveList']),
        parmId() {
            const { dirId } = this.$route.params;
            return dirId
        },
        activeItemNull() {
            return !this.activeItem.dirId && (!this.activeItem.label) && (!this.activeItem.level)
        }
    },
    data() {
        return {
            activeItem: {
                dirId: null,
                label: null,
                level: null,
            },
            sysList: [{
                label: '全部合同',
                dirId: '0',
                level: 0,
                isSys: true,
                icon: 'el-icon-ali-quanbuhetong'
            },
            {
                label: '未归档合同',
                dirId: '-1',
                level: 0,
                isSys: true,
                icon: 'el-icon-ali-weiguidanghetong'
            }],
            disabled: false
        }
    },
    mounted() {
        this.getArchiveList();
    },
    methods: {
        ...mapActions('documents', ['getArchiveList']),
        clearitem() {
            this.activeItem = {
                dirId: null,
                label: null,
                level: null,
            }
        },
        itemClick(item) {
            if (item.dirId == this.activeItem.dirId) {
                this.clearitem()
            } else {
                this.activeItem = item;
            }
        },
        itmClick({ dirId, isSys }) {
            isSys && this.clearitem()
            if (dirId != this.$route.params.dirId) {
                this.$router.replace(`/electronicDocumentsList/${dirId}`)
            }
        },
        isActive({ dirId }) {
            return this.activeItem.dirId === dirId;
        },
        onRightClick(event) {
            this.revision(event);
            this.disabled = true
        },
        add() {
            if (!this.activeItem.isSys) {
                if (!this.activeItem.dirId) {
                    this.$refs.dirForm.show({ type: 'add', data: { dirId: null } })
                } else {
                    this.$refs.dirForm.show({ type: 'add', data: { dirId: this.activeItem.dirId } })
                }
            } else {
                this.$message('系统目录不能添加子菜单');
            }
        },
        revision(data) {
            this.$refs.dirForm.show({ type: 'revision', data })
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;

    .item {
        width: 168px;
        height: 40px;
        top: 48px;
        gap: 0px;
        border-radius: 4px 0px 0px 0px;
        opacity: 0px;
        display: flex;
        padding: 0 20px;
        margin-top: 8px;
        align-items: center;
        cursor: pointer;

        i {
            margin-right: 8px;
        }
    }

    .item2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item3 {
        padding: 0 30px;
    }

    .active {
        background: #fff;
        border-radius: 4px;
        color: #0062FF;
    }

    .fold {
        //折叠
        height: 0px;
        overflow: hidden;
    }

    .blue {
        color: #0062FF;
    }
}
</style>