import api from '@/utils/api'
const state = {
	electronicData: {
		subject: '',
		files: [],
		signers: [],
		file: '',
		save: false,
		insuranceId: null,
		insuranceVersion: null,
		title: ''
	},
	enterpriseSearch: [],   // 企业查询
	personalSearch: [],// 个人查询
	contractProfile: {
		all: '',//合同总数
		finishedCount: '',//完成数量
		inProgressCount: '',//签署中数量
		nearDeadlineCount: '',//即将到期数量
		refusedCount: '',//拒签数量
		unarchivedCount: '',//未归档数量
		unprotectedCount: '',//未保障数量
		protectedCount: '',//保障中数量
		expiredCount: '',//已到期数量
		insuranceInProcessCount: '',//拒签数量
		terminatedCount: '',//已完结数量
	}

}
const mutations = {
	setElectronic(state, payload) {
		state.electronicData = {
			...state.electronicData,
			...payload
		}
	},
	setContractProfile(state, payload) {
		state.contractProfile = {
			...state.contractProfile,
			...payload
		}
	},

	// 保存历史记录
	saveHistory(state, payload) {
		payload.forEach(item => {
			if (item.type === 'ORG') {
				if (state.enterpriseSearch.findIndex(itm => (itm.operatorIdNumber == item.operatorIdNumber) && (itm.idNumber == item.idNumber)) < 0)
					state.enterpriseSearch.push({
						...item
					})
			} else if (item.type === 'PERSON') {
				if (state.personalSearch.findIndex(itm => itm.operatorIdNumber == item.operatorIdNumber) < 0)
					state.personalSearch.push({
						...item
					})
			}
		});

	}
}
const actions = {
	async getContractProfile({ commit, state }) {     //合同统计
		const { status, data } = await api.getContractProfile()
		if (status === 200) {
			commit('setContractProfile', data);
		}
	},
}
export default {
	namespaced: true,
	state,
	mutations,
	actions
}