/*
* @Author: 曹俊杰
* @Date: 2022-05-10 17:52:49
* @Module: 三要素核查
*/
<template>
  <div class="inspect">
    <div class="left">
      <c-menu :list="menuList" />
    </div>
    <div class="right">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import cMenu from "@/components/layouts/cMenu.vue"
export default {
  components: {
    cMenu
  },
  data() {
    return {
      menuList: [
        {
          title: '风险排查',
          name: 'inspectHome',
          icon: 'el-icon-ali-fengxianpaicha'
        },
        {
          title: '排查记录',
          name: 'inspectList',
          icon: 'el-icon-ali-paichajilu',
          authority: []
        }
      ]
    };
  },
}
</script>
<style lang='scss' scoped>
.inspect {
  display: flex;
  padding-top: 16px;

  .left {
    width: 208px;
    padding-left: 24px;
    padding-right: 16px;
  }

  .right {
    flex: 1;
    padding-right: 20px;
  }
}
</style>