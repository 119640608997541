/*
* @Author: 曹俊杰
* @Date: 2022-04-12 12:01:43
* @Module: 电子文书
*/
<template>
  <div class="box">
    <div class="left">
      <el-button type="primary" style="width: 100%;"
        @click="$router.push({ name: 'electronicDocumentsAdd' })">发起签约</el-button>
      <div class="title">
        <div>目录</div>
        <div class="btn">
          <span class="el-icon-plus"></span>
          <span class="" @click="add">创建分类</span>
        </div>
      </div>

      <e-menu ref="menu" />

    </div>
    <div class="right">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>

import eMenu from "@/views/ElectronicDocuments/components/eMenu.vue";
import { mapState } from "vuex";

export default {
  components: {
    // hdqPagination,
    // qrDialog,
    // fileSelect,
    // filtrate,
    eMenu
  },
  data() {
    return {


      operateId: [],
      operatorName: '',
      listParams: {
        keyword: '',
        zhuangtai: "0"
      },
      fold: true, // 折叠
    };
  },
  computed: {
    ...mapState({
      companyIdNumber: state => state.user.user.companyIdNumber
    })
  },
  mounted() {
    // this.getContract()
  },
  filters: {
    stateStyle(type) {
      if (type == 'IN_PROGRESS') {
        return '签署中'
      } else if (type == 'FINISHED') {
        return '签署完成'
      } else if (type == 'CANCELED') {
        return '已撤消'
      } else if (type == 'TERMINATED') {
        return '终止'
      } else if (type == 'EXPIRED') {
        return '过期'
      } else if (type == 'REFUSED') {
        return '拒签'
      } else {
        return type
      }

    }
  },
  methods: {
    add() {
      this.$refs.menu.add()
    },
    // 筛选状态点击事件
    choose(value) {
      this.listParams.zhuangtai = value
    },
    async getContract() {
      const { current, size } = this.$refs.pagination
      const listParams = this.listParams;
      const { status, data } = await this.$api.getContract({ current, size, ...listParams })
      if (status === 200) {
        const { records } = data
        this.list = records
        this.$refs.pagination.createPagination(data)
      }
    },
    more() {
      this.$router.push({
        name: 'electronicDocumentsAdd'
      })
    },
    downFile(fileId) {
      this.$store.dispatch('documents/getFile', {
        fileId, callback: (res) => {
          this.$utils.open(res.downloadUrl)
        }
      })
    },
    showQRCode({ uuid, operatorName, operatorIdNumber }) {

      this.$prompt(`请输入签署人${operatorName}身份证后四位`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: `请输入签署人${operatorName}身份证后四位`,
        inputPattern: /^[0-9xX]{4}$/,
        inputErrorMessage: '身份证后四位格式不正确',
        type: 'warning'
      }).then(({ value }) => {
        if (operatorIdNumber.substring(operatorIdNumber.length - 4) == value) {
          this.operatorName = operatorName;
          this.getSignerUrl({ uuid });
        } else {
          this.$message({
            type: 'error',
            message: `您输入的${operatorName}身份证后四位错误`
          });
        }
      }).catch(() => ({}));
    },
    async getSignerUrl(parm) {
      const { status, data, info } = await this.$api.getSignerUrl(parm)
      if (status === 200) {
        this.$refs.qrDialog.show(data.signUrl)
      } else {
        this.$message({
          type: "error",
          message: info
        })
      }
    },

    operate({ row: { docUuid } }) {
      if (docUuid) {
        this.operateId = [docUuid];
        this.$refs.fileSelect.show();
      } else {
        this.$message({
          type: 'warning',
          message: '签署完成才可归档'
        })
      }
    },
    //  归档选择回调
    fileSelectCallback({ data }) {
      this.$confirm(`选择归档目录为${data.label}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.putArchiveDocArvhive({
          parentUuid: data.uuid,
          uuidList: this.operateId
        }).then(({ status, info }) => {
          if (status === 200) {
            this.$message({
              type: 'success',
              message: info
            });
            this.$refs.fileSelect.hidden();
            this.getContract()
          } else {
            this.$message({
              type: 'error',
              message: info
            });
          }
        })

      })
    },
    // 撤销合同
    async deleteContract({ uuid, subject }) {
      const { current, size } = this.$refs.pagination;
      try {
        await this.$confirm(`撤销合同《${subject}》,撤销后不可恢复,请慎重操作。是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteContract({ uuid })
        if (status == 200) {
          this.$message({
            type: 'success',
            message: info
          })
          console.log(current, size)
          setTimeout(() => this.getContract(current, size), 1000)
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
        if (error == 'cancel') {
          this.$message({
            message: `取消撤销《${subject}》`
          })
        }
      }
    }
  },
};
</script>
<style lang='scss' scoped>
.box {
  padding: 16px 24px;
  display: flex;

  .left {
    margin-right: 16px;
    width: 168px;

    .title {
      height: 40px;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      .btn {
        color: #0062FF;
        cursor: pointer;
      }
    }
  }

  .right {
    flex: 1;
  }
}



@import "~@/assets/style/var.scss";

.el-icon-user .el-icon-ali-wenjianjia {
  color: $--color-primary;
  margin-right: 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 250px;
  line-height: 1;
}
</style>