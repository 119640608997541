/*
* @Author: 曹俊杰
* @Date: 2023-01-03 16:02:31
* @Module: 菜单
*/
<template>
  <ul class="menu">
    <router-link v-for="item in menuList" class="item pointer" :style="$route.name === 'home'?'color:#fff':''" :active-class="$route.name === 'home' ? 'home-active' : 'def-active'"
      tag='li' :key="item.name" :to='`/${item.name}`'>
      {{ item.title }}
      <div class="line"></div>
    </router-link>
  </ul>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      menuList: [
        {
          title: '工作台',
          name: 'home'
        },
        {
          title: '合同',
          name: 'electronicDocumentsList',
          authority: []
        },
        {
          title: '风险排查',
          name: 'inspect',
          authority: []
        },
        {
          title: '账户中心',
          name: 'accountCenter',
          authority: []
        },
        {
          title: '出证',
          name: 'accurateEvidence',
          authority: []
        },
        {
          title: '权益执行',
          name: 'equity',
          authority: []
        }
      ],
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
 
  mounted() { },
  methods: {},
};
</script>
<style lang='scss' scoped>
@import "~@/assets/style/var.scss";

.menu {
  // background: #f66;
  // width: 100%;
  flex: 1;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .item {
    margin-right: 40px;
    position: relative;
  }

  .def-active {
    color: $--color-primary !important;

    .line {
      display: block;
      background: #0062FF;
    }
  }
  .home-active {
    color: #fff;

    .line {
      display: block;
      background: #fff;
    }
  }


  .line {
    width: 16px;
    height: 2px;
    border-radius: 8px;

    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
  }
}
</style>