<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    this.$im.intIm();
    this.$im.customer();
  },
  methods: {
  }
}

</script>
<style lang="scss">
@import "~@/assets/style/reset.scss";
@import "~@/assets/style/var.scss";

html,
body {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

#app {
  widows: 100vh;
  height: 100vh;
  background: #F3F6F8;
}

.primary {
  color: $--color-primary !important;
}

.pointer {
  cursor: pointer;
}

.keep2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.viewer-title {
  font-size: 30px !important;
  font-weight: 700 !important;
}

.border {
  background: #ffffff;
  border-radius: 4px;
  background-color: #fff;
}
</style>
