/*
* @Author: 曹俊杰
* @Date: 2024-10-10 22:16:59
* @Last Modified by: 曹俊杰杰
* @Last Modified time: 2024-10-11 11:49:344
*/ 课程列表

<template>
    <div class="comtent">
        <div class="item" v-for="item in list" :key="item.courseId" @click="toDetail(item)">
            <el-image style="width: 100%; height: 124px" :src="`${$oss}${item.cover}`"></el-image>
            <div class="item-title">{{ item.name }}</div>
            <div class="item-long">共 {{ item.videoCount }} 节课</div>
            <div class="item-bottom">
                <div class="text" v-if="item.bought">已购买</div>
                <div class="price" v-else>¥{{ item.price }}</div>
                <div class="browse">{{ item.viewCount }}人浏览</div>
            </div>
        </div>
        <buy ref="buy" @success="getCourseList"/>
    </div>
</template>

<script>
import buy from "./buy.vue"
export default {
    components: {
        buy
    },
    data() {
        return {
            list: []
        }
    },
    mounted() {
        this.getCourseList()
    },
    methods: {
        async getCourseList() {
            const { status, data } = await this.$api.getCourseList()
            if (status == 200) {
                this.list = data.records
            }
        },
        async toDetail(res) {
            const { courseId, videoCount, bought, price } = res
            if (bought) {   //是否购买
                if (videoCount) {
                    this.$router.push({ path: `/course/${courseId}` });
                } else {
                    this.$message({
                        type: "error",
                        message: '当前课程没有视频内容，请联系管理员'
                    })
                }
            } else {
                const balance = await this.$store.dispatch("user/buy", price);
                this.$refs.buy.show({balance,...res})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.comtent {
    display: flex;
    flex-wrap: wrap;

    .item {
        width: calc((100% - (20px * 3))/4);
        height: 250px;
        margin-right: 20px;
        margin-bottom: 16px;

        &-title {

            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            cursor: pointer;

            &:hover {
                color: #0062FF;
            }
        }

        &-long {
            color: #999;
            font-family: "Source Han Sans CN";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .price {
                color: #FF0D0D;
                font-family: "Source Han Sans CN";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .browse {
                color: #999;
                font-family: "Source Han Sans CN";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .text {
                color: #0062FF;
                font-family: "Source Han Sans CN";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

    :nth-child(4n) {
        margin-right: 0px;
    }
}
</style>