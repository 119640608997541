export default {
    data() {
        return {
            contractStateList: [    //合同状态
                {
                    label: '签署中',
                    value: 'IN_PROGRESS',
                    color: '#FF8412'
                },
                {
                    label: '签署完成',
                    value: 'FINISHED',
                    color: '#00C152;'
                },
                {
                    label: '撤消',
                    value: 'CANCELED',
                    color: '#999'
                },
                {
                    label: '终止',
                    value: 'TERMINATED',
                    color: '#FF0D0D'
                },
                {
                    label: '过期',
                    value: 'EXPIRED',
                    color: '#666'
                },
                {
                    label: '拒签',
                    value: 'REFUSED',
                    color: '#FF0D0D'
                }
            ],
            insuranceState: [{   //合同宝状态
                label: '未保障',
                value: 0,
            },
            {
                label: '不可投保',
                value: 1,
            },
            {
                label: '保障中',
                value: 2,
            },
            {
                label: '已到期',
                value: 3,
            },
            {
                label: '执行中',
                value: 4,
            },
            {
                label: '已完结',
                value: 5,
            }],
            certificateType: [  //出证类型
                {
                    label: '电子版',
                    value: 0
                },
                {
                    label: '纸质版',
                    value: 1
                }
            ],
            certificateState: [
                {
                    label: '未付款',
                    value: 0
                },
                {
                    label: '出证中',
                    value: 1
                },
                {
                    label: '已交付',
                    value: 2
                }
            ],
            transactionType: [  //消费类型
                {
                    label: '合同',
                    value: "CONTRACT"
                },
                {
                    label: '出证',
                    value: "CERTIFICATE"
                },
                {
                    label: '合同保',
                    value: "INSURANCE"
                },
                {
                    label: '三要素核验',
                    value: "VERIFICATION"
                },
                {
                    label: '课程',
                    value: "COURSE"
                }
            ],
            enforceType: [
                {
                    label: '法院',
                    value: 1
                }, {
                    label: '商事仲裁',
                    value: 2
                }
            ],
            subjectType: [{
                label: '法人',
                value: 1
            },
            {
                label: '自然人',
                value: 2
            }]

        }
    }
}