<template>
    <div class="box">

        <div class="top">
            <div class="person"></div>
            <div class="title">在线法务咨询</div>
            <div class="time">工作日：12:00 - 22:00 为您服务</div>
            <div class="btn pointer" @click="triggerMessage">开通服务</div>
        </div>
        <div class="bottom">
            <div class="text">日常咨询</div>
            <div class="solid">|</div>
            <div class="text">合规保障</div>
            <div class="solid">|</div>
            <div class="text">合法维权</div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        triggerMessage(){
            this.$im.triggerMessage("开通服务")
        }
    }
}
</script>

<style lang="scss" scoped>
$--person-offset: 22px;

.box {
    .top {
        height: 156px;
        background-image: url(~@/static/image/home/home_bg_2.png);
        background-size: 95% 156px;
        background-repeat: no-repeat;
        background-position: center $--person-offset;
        background-color: linear-gradient(180deg, #2743C9 0%, #4981F5 100%);
        position: relative;
        padding-left: calc(5% + 20px);
        overflow: hidden;

        .person {
            background-image: url(~@/static/image/home/home_bg_3.png);
            background-size: auto 156px;
            background-repeat: no-repeat;
            background-position: bottom right;
            position: absolute;
            right: 0;
            bottom: 0;
            height: 156px;
            width: 127px;
        }

        .title {
            color: #FFF;
            font-family: "Source Han Sans CN";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-top: calc(16px + 22px);
        }

        .time {
            color: #fff;
            font-family: "Source Han Sans CN";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.5;
            margin-top: 6px;
        }

        .btn {
            width: 88px;
            height: 29px;
            flex-shrink: 0;
            border-radius: 33px;
            background: #fff;
            color: #0062FF;
            font-size: 14px;
            font-weight: 500;
            line-height: 29px;
            text-align: center;
            margin-top: 20px;
        }

        ;
    }

    .bottom {
        height: 56px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 13%;

        .text {
            color: #666;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .solid{
            color: #eee;
            width: 14px;
            text-align: center;
        }

    }
}
</style>